<template>
  <div class="no_touch">
    <!--   查看行动轨迹   -->

    <spoor-map class="map" ref="map" :zoom="zoom"/>

    <div class="contr-button">
      <img class="img" @click="showSettingWidow" src="../assets/history_systemsetting_press.png">
      <img class="img" @click="changePlayStatus" :src=playBtn>
    </div>

    <div class="contr-time">
      从
      <span class="sel-time" @click="selectStartTime">{{ formatStartTime }}</span>
      到
      <span class="sel-time" @click="selectEndTime">{{ formatEndTime }}</span>
    </div>

    <van-popup v-model="showDatePopup" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker
          v-model="currentDate"
          type="datetime"
          title="选择年月日时分"
          @confirm="confirmDate"
          :min-date="minDate"
          :max-date="maxDate"
      />
    </van-popup>

    <van-dialog v-model="showSettingPopup" title="设置" @confirm="confirmSetting" show-cancel-button>
      <div class="contr-setting">
        <span style="{display: block}">播放显示方式</span>
        <van-radio-group v-model="PlayMode">
          <van-radio class="radio-play-mode" name="0">画线</van-radio>
          <van-radio name="1">描点</van-radio>
          <van-radio name="2">描点且画线</van-radio>
        </van-radio-group>
        <span class="text-title-speed">轨迹播放速度</span>
        <van-radio-group v-model="PlaySpeedGreed">
          <van-radio class="radio-play-mode" name="200">很快</van-radio>
          <van-radio name="400">快</van-radio>
          <van-radio name="600">正常</van-radio>
          <van-radio name="800">慢</van-radio>
          <van-radio name="1000">很慢</van-radio>
        </van-radio-group>
        <div class="contr-setting-item">
          <span>信息窗口</span>
          <van-switch class="item-switch" v-model="showInfoWindow"/>
        </div>
        <div class="contr-setting-item">
          <span>加载基站数据</span>
          <van-switch class="item-switch" v-model="showLbs"/>
        </div>
        <div class="contr-setting-item-last">
          <span>加载wifi数据</span>
          <van-switch class="item-switch" v-model="showWifi"/>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {post} from "@/router/httpUtils";
import {MapType, State_0, State_100, TimeOffset} from "@/constants/contants";
import SpoorMap from "@/components/SpoorMap";
import {Toast} from "vant";
import imgGPS from '@/assets/device_location_gps.png'
import imgLBS from '@/assets/device_location_lbs.png'
import imgWIFI from '@/assets/device_location_wifi.png'
import imgPlay from '@/assets/playbtn_old.png'
import imgPause from '@/assets/pausebtn_old.png'

export default {
  name: "Spoor",
  components: {SpoorMap},
  data() {
    return {
      menuText: '播放',
      zoom: 16,
      startTime: '',
      endTime: '',
      showDatePopup: false,
      showSettingPopup: false,
      currentDate: new Date(),
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2100, 12, 31),
      playBtn: imgPlay,

      PlayMode: "0",
      PlaySpeedGreed: "600",
      showInfoWindow: true,
      showLbs: true,
      showWifi: true,

      timer: null,
    }
  },
  mounted() {
    const date = new Date()
    this.endTime = date
    this.startTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0)

    this.showLbs = localStorage.showLbs ? localStorage.showLbs === "true" : true
    this.showWifi = localStorage.showWifi ? localStorage.showWifi === "true" : true
    this.PlayMode = localStorage.PlayMode ? localStorage.PlayMode : "0"
    this.PlaySpeedGreed = localStorage.PlaySpeedGreed ? localStorage.PlaySpeedGreed : "600"
  },
  computed: {
    formatStartTime() {
      const date = this.startTime
      if (!date) return
      const day = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
      return day + ' ' + date.getHours() + ':' + date.getMinutes()
    },
    formatEndTime() {
      const date = this.endTime
      if (!date) return
      const day = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
      return day + ' ' + date.getHours() + ':' + date.getMinutes()
    }
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    onClickRight() {
      if (this.menuText === '播放') {
        this.menuText = "暂停"

      } else {
        this.menuText = "播放"

      }
    },
    confirmDate(date) {
      console.log(date)
      if (date > this.endTime) {
        this.endTime = date
      } else {
        this.startTime = date
      }
      this.showDatePopup = false
    },
    selectStartTime() {
      this.currentDate = this.startTime
      this.showDatePopup = true
    },
    selectEndTime() {
      this.currentDate = this.endTime
      this.showDatePopup = true
    },
    confirmSetting() {
      console.log('confirmSetting')
      this.playBtn = imgPlay
      this.changePlayStatus()
    },
    showSettingWidow() {
      this.showSettingPopup = true
    },
    changePlayStatus() {
      if (this.playBtn === imgPlay) {
        this.playBtn = imgPause
        this.getSpoor()
      } else {
        this.playBtn = imgPlay
        if (this.timer) clearInterval(this.timer)
      }
    },
    getSpoor() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      const utcStartTime = this.startTime.getUTCFullYear() + '-' + (this.startTime.getUTCMonth() + 1) + '-' + this.startTime.getUTCDate() + ' ' + this.startTime.getUTCHours() + ':' + this.startTime.getUTCMinutes()
      const utcEndTime = this.endTime.getUTCFullYear() + '-' + (this.endTime.getUTCMonth() + 1) + '-' + this.endTime.getUTCDate() + ' ' + this.endTime.getUTCHours() + ':' + this.endTime.getUTCMinutes()
      post('/api/Location/NewsHistory', {
        "DeviceId": localStorage.DeviceId,
        "EndTime": utcEndTime,
        "MapType": MapType,
        "SelectCount": 800,
        // "ShowLbs": this.showLbs ? 1 : 0,
        // "ShowWifi": this.showWifi ? 1 : 0,
        "StartTime": utcStartTime,
        "TimeOffset": TimeOffset,
        "Token": localStorage.Token,
        // 定位类型 0: GPS,1: LBS,2: Wifi,3: GPS+LBS,4: GPS+Wifi,5: LBS+Wifi,6: GPS+LBS+Wifi
        "PositionType": this.showLbs && this.showWifi ? 5 : this.showWifi ? 2 : 1
      }).then(res => {
        console.log(res)
        console.log(res.Items.length)

        Toast.clear()

        localStorage.PlayMode = this.PlayMode
        localStorage.showLbs = this.showLbs
        localStorage.showWifi = this.showWifi
        localStorage.PlaySpeedGreed = this.PlaySpeedGreed

        const historyList = res.Items
        if (res.State == State_100 || historyList.length <= 0) {
          Toast.fail({
            message: '无历史轨迹数据!'
          })
          this.playBtn = imgPlay
          return
        }

        if (res.State != State_0) {
          Toast.fail({
            message: '获取历史足迹失败!'
          })
          this.playBtn = imgPlay
          return
        }

        // this.timer = setInterval(() => {
        //   this.planHistory(res.Items)
        // }, this.PlaySpeedGreed)

        this.planHistory(res.Items)
      }).catch(err => {
        Toast.clear()
        Toast.fail({
          message: '加载失败！'
        })
        console.log(err)
      })
    },
    planHistory(historyList) {
      let index = 0

      this.$refs.map.clear()
      if (this.timer) clearInterval(this.timer)

      this.timer = setInterval(() => {
        if (index >= historyList.length - 1) {
          this.playBtn = imgPlay
          Toast.success({
            message: "历史回放播放结束!"
          })
          clearInterval(this.timer)
        }

        const item = historyList[index]
        console.log(item)
        let imgMarker;
        // marker
        switch (item.DataType) {
          case 1:
            imgMarker = imgGPS
            break
          case 2:
            imgMarker = imgLBS
            break
          case 3:
            imgMarker = imgWIFI
            break
        }
        
        const time = new Date(item.Time)
        const utcTime = new Date(Date.UTC(time.getFullYear() , time.getMonth() , time.getDate() , time.getHours() , time.getMinutes() , time.getSeconds()))

        this.$refs.map.planTo(imgMarker, this.PlayMode, utcTime.toLocaleDateString() + " " + utcTime.toLocaleTimeString(), item.Lat, item.Lng)
        index++
      }, this.PlaySpeedGreed)
    }
  }
}
</script>

<style scoped>
.contr-time {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
}

.sel-time {
  background-color: lightblue;
  padding: 5px;
}

.map {
  height: calc(100vh - 40px);
}

.button {
  width: 50%;
}

.contr-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

.img {
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
  display: block;
}

.contr-setting {
  margin: 10px;
}

.contr-setting-item {
  border-bottom: 1px solid #969799;
  padding-bottom: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contr-setting-item-last {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-title-speed {
  display: block;
  margin-top: 10px
}

.van-radio-group {
  display: flex;
  margin-top: 10px;
  border-bottom: 1px solid #969799;
  padding-bottom: 10px;
}

.van-switch {
  font-size: 24px;
}

/*.radio-play-mode {*/
/*  margin-right: 4px;*/
/*  margin-left: 4px;*/
/*}*/
.van-radio {
  font-size: 12px;
  margin-right: 8px;
}
</style>
