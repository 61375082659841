<template>
  <div class="map" id="map">
  </div>
</template>

<script>
import BMap from 'BMap'
import imgWIFI from '@/assets/device_location_wifi.png'

export default {
  name: "SpoorMap",
  props: {
    zoom: Number,
    radius: Number,
  },
  watch: {
    radius: {
      handler(data) {
        this.sizeArea(data)
      },
      deep: true
    },
  },
  mounted() {
    this.center = {
      lng: localStorage.lng,
      lat: localStorage.lat
    }
    this.createMap()
  },
  data() {
    return {
      map: null,
      marker: null,
      polyline: null,
      label: null,
      center: {lng: 0, lat: 0},
    }
  },
  methods: {
    createMap() {
      /* eslint-disable */
      console.log(this.center)
      // 创建Map实例
      const map = new BMap.Map("map");
      // 初始化地图,设置中心点坐标和地图级别
      const point = new BMap.Point(this.center.lng, this.center.lat)
      map.centerAndZoom(point, this.zoom)

      // 设置地图显示的城市 此项是必须设置的
      map.setCurrentCity("北京")

      //开启鼠标滚轮缩放
      map.enableScrollWheelZoom(true)

      // console.log(1)
      // const markerSize = new BMap.Size(26, 26)
      // const markerIcon = new BMap.Icon(imgWIFI, markerSize, {
      //   anchor: new BMap.Size(13, 26),
      //   imageSize: markerSize
      // });
      // map.addOverlay(new BMap.Marker(point, {
      //   icon: markerIcon
      // }))

      // 拖拽监听
      // map.addEventListener("dragend", () => {
      //   this.moveArea()
      // })

      this.map = map
      /* eslint-enable */
    },
    planTo(imgMarker, PlayMode, time, lat, lng) {
      console.log(imgMarker)
      console.log(PlayMode)
      console.log(time)
      console.log(lat)
      console.log(lng)
      this.center = {
        lng: lat,
        lat: lng
      }
      this.map.centerAndZoom(new BMap.Point(lng, lat), this.zoom)

      const point = new BMap.Point(lng, lat)

      // 描点
      const markerSize = new BMap.Size(26, 26)
      const markerIcon = new BMap.Icon(imgWIFI, markerSize, {
        anchor: new BMap.Size(13, 26),
        imageSize: markerSize
      });
      if (PlayMode !== "0") {
        this.map.addOverlay(new BMap.Marker(point, {
          icon: markerIcon
        }))
      } else {
        this.map.removeOverlay(this.marker)
        this.marker = new BMap.Marker(point, {
          icon: markerIcon
        })
        this.map.addOverlay(this.marker)
      }

      if (PlayMode !== "1") {
        // 画线
        if (!this.polyline) {
          this.polyline = new BMap.Polyline([point], {strokeColor: "lightgreen", strokeWeight: 1, strokeOpacity: 1});   //创建折线
          this.map.addOverlay(this.polyline);   //增加折线
        } else {
          let path = this.polyline.getPath()
          path.push(point)
          this.polyline.setPath(path)
        }
      }

      if (time) {
        if (this.label) {
          this.label.setPosition(point)
          this.label.setContent(time)
        } else {
          const opts = {
            position: point,    // 指定文本标注所在的地理位置
            offset: new BMap.Size(-63, -50)    //设置文本偏移量
          };
          this.label = new BMap.Label(time, opts);  // 创建文本标注对象
          this.label.setStyle({
            color: "red",
            fontSize: "12px",
            height: "20px",
            lineHeight: "20px",
            fontFamily: "微软雅黑"
          });
          this.map.addOverlay(this.label);
        }
      }
    },
    clear() {
      this.marker = null
      this.polyline = null
      this.label = null
      this.map.clearOverlays()
    }
  },
}
</script>

<style scoped>
.map {
  height: 100%;
}
</style>